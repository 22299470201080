<template>
  <v-app>
    <base-app-bar />
    
    <base-view />

    <v-snackbar
      v-model="$store.getters['app/snackbar'].show"
      :bottom="$store.getters['app/snackbar'].y === 'bottom'"
      :color="$store.getters['app/snackbar'].color"
      :left="$store.getters['app/snackbar'].x === 'left'"
      :multi-line="$store.getters['app/snackbar'].mode === 'multi-line'"
      :right="$store.getters['app/snackbar'].x === 'right'"
      :timeout="$store.getters['app/snackbar'].timeout"
      :top="$store.getters['app/snackbar'].y === 'top'"
      :vertical="$store.getters['app/snackbar'].mode === 'vertical'"
      style="padding-top: unset; width: 100vw;"
    >
      <v-icon         
        class="pad-right" 
        v-text="$store.getters['app/snackbar'].icon"
      />

      {{ $store.getters['app/snackbar'].text }}

      <template #action >
        <v-btn
          min-height="30"
          width="30"
          right
          text
          @click="$store.getters['app/snackbar'].show = false"
        >
          <v-icon
            v-text="$store.getters['app/snackbar'].closeIcon"
          />
        </v-btn>
      </template>
    </v-snackbar>

    <footer-view />
  </v-app>
</template>

<script>
  export default {
    name: 'BaseLayout',
    components: {
      BaseAppBar: () => import('./AppBar'),
      BaseView: () => import('./View'),
      FooterView: () => import('./Footer'),
    },
  };
</script>
